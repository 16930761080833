import './Partners.css';

export default function Partners() {
    return (
        <>
            <div className="font-pretendard">
                <div className='grid grid-cols-1 md:grid-cols-2'>
                    <div className='img-left'>
                        <div className='text-center'>
                            <h3 className='text-[35px] md:text-[50px] xl:text-[64px] text-white font-medium pt-12 md:pt-2'>livfarm <br className='md:hidden block'></br>  Partners</h3>
                            <div className='flex justify-center md:mt-[40px] mt-[15px]'>
                                <a href='https://partners.livfarm.com/'>
                                    <button className='zoom-button'>
                                        <img src='/assets/images/partners/button.svg' className='md:w-[188px] w-[100px]' alt='Button' />
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className='img-right'>
                        <div className='text-center'>
                            <h3 className='text-[35px] md:text-[50px] xl:text-[64px] text-white font-medium'>livfarm Grow <br className='md:hidden block'></br> Partners</h3>
                            <div className='flex justify-center md:mt-[40px] mt-[15px]'>
                                <a href='https://grow.livfarm.com/'>
                                    <button className='zoom-button'>
                                        <img src='/assets/images/partners/button_grow.svg' className='md:w-[188px] w-[100px]' alt='Button' />
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
