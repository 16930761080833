import React from 'react';
import './Application.css';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';

export default function Application() {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });

    const { ref: firstSectionRef, inView: firstSectionInView } = useInView({ triggerOnce: true });
    const { ref: secondSectionRef, inView: secondSectionInView } = useInView({ triggerOnce: true });
    const { ref: thirdSectionRef, inView: thirdSectionInView } = useInView({ triggerOnce: true });

    return (
        <>
            {
                isSmallScreen ? <div className='font-pretender'>
                    {/* livfarm greens */}
                    <section className={`text-livos-green lg:container`} style={{ marginTop: 100 }}>
                        <div className='' style={{ marginTop: 50, marginBottom: 90, }}>
                            <object data="/assets/images/app/Mobile/app1.svg" width={'100%'} type="image/svg+xml" aria-label="app 1"></object>
                        </div>
                    </section>
                    {/* livfarm greens */}
                    <section ref={firstSectionRef} className={`lg:container transition-all duration-500 ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                        <div className='' style={{ marginTop: 50, marginBottom: 90, }}>
                            <object data="/assets/images/app/Mobile/app2.svg" width={'100%'} type="image/svg+xml" aria-label="app 1"></object>
                        </div>
                    </section>

                    <div className='' style={{ marginBottom: 100 }}>
                        {/* image + text section */}
                        <section ref={secondSectionRef} className={`text-livos-green container transition-all duration-500 mb-8 pl-8 pr-8 ${secondSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 100, marginBottom: 100 }}>
                            <div className='grid grid-cols-1 gap-4'>
                                <div className='pl-9 mb-8'>
                                    <object data="/assets/images/app/img3.svg" width={'100%'} type="image/svg+xml" aria-label="킹 (체스 말)"></object>
                                    {/* <img src='/assets/images/app/img3.svg' /> */}
                                </div>
                                <div className='flex items-center text-center justify-center'>
                                    <div className=''>
                                        <h3 className='font-bold text-[15px] mb-[20px] lg:mb-[30px]'>가까운 매장에 미리 주문하면<br></br>
                                            기다리지 않고 바로 픽업</h3>
                                        <p className='text-[12px] font-semibold'>
                                            복잡한 도심 속 리브팜 샐러드와 함께 즐기는<br></br>
                                            보다 건강하고 여유있는 일상의 시작</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* App download */}
                        <section ref={thirdSectionRef} className={`text-livos-green container transition-all duration-500 ${thirdSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 100, marginBottom: 100 }}>
                            <div className='flex justify-center'>
                                <img src='/assets/images/app/App.png' alt='app' width={80} height={80} />
                            </div>
                            <div className='text-center lg:mb-[100px] lg:mt-[100px] mb-[74px] mt-[34px]'>
                                <h3 className='lg:text-4xl md:text-3xl font-bold mb-[15px] lg:mb-[40px]'>리브팜 공식 모바일 APP</h3>
                                <p className='text-[14px]'>언제나 믿을 수 있는 신선한 한 끼를 약속합니다</p>
                            </div>
                            <div className='mb-[25px] lg:mb-[35px] flex justify-center'>
                                <a href="https://play.google.com/store/apps/details?id=com.livfarm.crm&hl=en-KR"><img src='/assets/images/app/google_store_badge.svg' alt='google store' width={170} /></a>
                            </div>
                            <div className='flex justify-center'>
                                <a href='https://apps.apple.com/kr/app/%EB%A6%AC%EB%B8%8C%ED%8C%9C/id1666473311'><img src='/assets/images/app/app_store_badge.svg' alt='app store' width={170} /></a>
                            </div>
                        </section>
                    </div>
                </div> : <>
                    <div ref={firstSectionRef} className={`font-pretendard ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                        {/* livfarm greens */}
                        <section className={`text-livos-green lg:container`} style={{ marginTop: 200 }}>
                            <div className='' style={{ marginTop: 50, marginBottom: 200, }}>
                                <object data='/assets/images/app/app_img.svg' aria-label='app img' width={'100%'} />
                            </div>
                        </section>

                        {/* image + text section */}
                        <section ref={secondSectionRef} className={`text-livos-green container transition-all duration-500 mb-8 ${secondSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 200, marginBottom: 200 }}>
                            <div className='grid grid-cols-2  gap-4'>
                                <div className='flex justify-center'>
                                    <img src='/assets/images/app/img3.svg' alt='img3' />
                                </div>
                                <div className='flex items-center justify-center'>
                                    <div className=''>
                                        <h3 className='text-[40px] font-bold mb-[20px] lg:mb-[30px]'>가까운 매장에 미리 주문하면<br></br>
                                            기다리지 않고 바로 픽업</h3>
                                        <p className='text-[18px] lg:text-[24px] font-medium'>
                                            복잡한 도심 속 리브팜 샐러드와 함께 즐기는<br></br>
                                            보다 건강하고 여유있는 일상의 시작</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* App download */}
                        <section ref={thirdSectionRef} className={`text-livos-green container transition-all duration-500 ${thirdSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 200, marginBottom: 200 }}>
                            <div className='flex justify-center'>
                                <img src='/assets/images/app/App.png' alt='App' width={142} height={142} />
                            </div>
                            <div className='text-center lg:mb-[100px] lg:mt-[100px] mb-[74px] mt-[74px]'>
                                <h3 className='lg:text-[40px] md:text-[30px] font-bold mb-[15px] lg:mb-[40px]'>리브팜 공식 모바일 APP</h3>
                                <p className='lg:text-[24px] md:text-[18px] font-medium'>언제나 믿을 수 있는 신선한 한 끼를 약속합니다</p>
                            </div>
                            <div className='mb-[25px] lg:mb-[35px] flex justify-center'>
                                <a href="https://play.google.com/store/apps/details?id=com.livfarm.crm&hl=en-KR"><img src='/assets/images/app/google_store_badge.svg' height={100} alt='google store' /></a>
                            </div>
                            <div className='flex justify-center'>
                                <a href='https://apps.apple.com/kr/app/%EB%A6%AC%EB%B8%8C%ED%8C%9C/id1666473311'><img src='/assets/images/app/app_store_badge.svg' alt='app store' /></a>
                            </div>
                        </section>
                    </div>
                </>
            }
        </>
    );
}
