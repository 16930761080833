import React, { useState, useEffect, useRef } from 'react';
import { Store } from './model';
import { useMediaQuery } from 'react-responsive';

export interface CarouselProps {
    store: Store[];
}

const Carousel: React.FC<CarouselProps> = ({ store }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const [isAnimating, setIsAnimating] = useState(false);
    const startX = useRef(0);
    const endX = useRef(0);
    const intervalRef = useRef<NodeJS.Timeout | null>(null); // To store the interval ID

    const handleTouchStart = (e: React.TouchEvent) => {
        startX.current = e.touches[0].clientX;
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        endX.current = e.touches[0].clientX;
    };

    const handleTouchEnd = () => {
        const distance = startX.current - endX.current;
        if (distance > 50) {
            nextSlide();
        } else if (distance < -50) {
            prevSlide();
        }
    };

    const prevSlide = () => {
        if (!isAnimating) {
            setIsAnimating(true);
            setCurrentIndex(prevIndex => (prevIndex === 0 ? store.length - 1 : prevIndex - 1));
            setIsAnimating(false);
            resetInterval(); // Reset the interval
        }
    };

    const nextSlide = () => {
        if (!isAnimating) {
            setIsAnimating(true);
            setCurrentIndex(prevIndex => (prevIndex === store.length - 1 ? 0 : prevIndex + 1));
            setIsAnimating(false);
            resetInterval(); // Reset the interval
        }
    };

    const resetInterval = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }
        intervalRef.current = setInterval(() => {
            setIsAnimating(true);
            setCurrentIndex(prevIndex => (prevIndex === store.length - 1 ? 0 : prevIndex + 1));
            setIsAnimating(false);
        }, 5000);
    };

    useEffect(() => {
        resetInterval(); // Start the interval when the component mounts
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current); // Clean up the interval on unmount
            }
        };
    }, [store.length]);

    if (store.length === 0) {
        return <div>No data available</div>;
    }

    return (
        <div
            className="relative max-w-[1196px] overflow-hidden font-pretendard"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
        >
            <div className="flex transition-transform duration-500" style={{ transform: `translateX(-${currentIndex * 100}%)` }}>
                {store.map((item, index) => (
                    <div key={index} className="w-full flex-shrink-0">
                        <img
                            src={item.image}
                            alt={`Slide ${index}`}
                            className="w-full h-full object-cover"
                        />
                    </div>
                ))}
            </div>
            <div className="relative md:h-[250px] h-[200px] mt-2 flex items-center justify-center">
                <div className="text-center text-livos-green">
                    <a href={store[currentIndex].mapurl}>
                        <p className="md:text-[50px] text-[20px] font-medium flex items-center justify-center">
                            {store[currentIndex].title}
                            <img src="/assets/images/location/link_icon.svg" alt="Link" className="ml-4 w-[15px] h-[15px] md:h-[30px] md:w-[30px]" />
                        </p>
                    </a>
                    <p className='leading-[15px] md:leading-normal'>
                        <p className="font-crimson text-[13px] md:text-[22px] font-normal">{store[currentIndex].location}</p>
                        <p className="font-crimson text-[13px] md:text-[22px] font-normal">{store[currentIndex].phone}</p>
                        <p className="font-crimson text-[13px] md:text-[22px] font-normal">{store[currentIndex].description}</p>
                    </p>
                </div>
                {/* Navigation Buttons */}
                {!isSmallScreen && (
                    <>
                        <div className="absolute inset-y-0 left-0 flex items-center">
                            <button onClick={prevSlide} className="hover:scale-100 transform transition-none">
                                <img src="/assets/images/location/left_button.svg" alt="Previous" />
                            </button>
                        </div>
                        <div className="absolute inset-y-0 right-0 flex items-center">
                            <button onClick={nextSlide} className="hover:scale-100 transform transition-none">
                                <img src="/assets/images/location/right_button.svg" alt="Next" />
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Carousel;
