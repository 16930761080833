import React, { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import Carousel from './Carousel';
import axios from 'axios';
import { Store } from './model';

export default function Location() {
    const [store, setStore] = useState<Store[]>();
    const { ref: firstSectionRef, inView: firstSectionInView } = useInView({ triggerOnce: true });

    useEffect(() => {
        // Fetch menu from the API
        const fetchMenu = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}store`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                const data = response.data;
                const fetchData = JSON.parse(data);
                setStore(fetchData);
            } catch (error) {
                console.error('Error fetching menu:', error);
            }
        };

        fetchMenu();
    }, []);

    return (
        <>
            <div className='min-h-[78vh]'>
                {/* livfarm greens */}
                <section
                    ref={firstSectionRef}
                    className={`text-livos-green container transition-all duration-500 ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'
                        }`}
                    style={{ marginTop: 150, marginBottom: 50 }}
                >
                    <div className="text-center gap gap-4">
                        <div className="flex justify-center">
                            <img src="/assets/images/location/location_box.svg" alt='Img not found' className="md:w-[288px] w-[100px]" />
                        </div>
                    </div>
                </section>
                <section className='container pl-[1px] pr-[1px] mb-[100px] '>
                    <div className="max-w-screen-lg flex items-center justify-center mx-auto">
                        <div className="text-center">
                            {store ? <Carousel store={store} /> : <div className="h-[250px]">Loading</div>}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}
