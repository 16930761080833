import React from 'react';
import './Home.css';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

export default function Home() {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const { t } = useTranslation();
    const { ref: firstSectionRef, inView: firstSectionInView } = useInView({ triggerOnce: true });
    const { ref: secondSectionRef, inView: secondSectionInView } = useInView({ triggerOnce: true });
    const { ref: thirdSectionRef, inView: thirdSectionInView } = useInView({ triggerOnce: true });
    const { ref: fourthSectionRef, inView: fourthSectionInView } = useInView({ triggerOnce: true });

    return (
        <>
            {
                isSmallScreen ? (
                    <div className='mb-32'>
                        <div className='video-content-sm'>
                            <video
                                autoPlay
                                loop
                                muted
                                playsInline
                                className="absolute z-10 w-auto min-w-full min-h-[650px] object-cover"
                            >
                                <source
                                    src="https://livfarm-hompage-v2.s3.ap-northeast-2.amazonaws.com/home/video.mp4"
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        {/* second text section */}
                        <section ref={firstSectionRef} className={`text-livos-green container transition-all duration-500 ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 100, marginBottom: 100 }}>
                            <div className='text-center gap gap-4'>
                                <h3 className='text-[20px] font-bold mb-[20px] font-pretendard'>{t('main_near')}</h3>
                                <p className='text-[15px] font-pretendard text-medium'>
                                    {t('m_main_freshness').split('\n').map((line, index, array) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}</p>
                            </div>
                        </section>

                        {/* card */}
                        <div className='container max-w-[500px]'>
                            <section ref={secondSectionRef} className={`text-livos-green p-4 transition-all duration-500 ${secondSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                                <div>
                                    <div className='w-full'>
                                        <img src='/assets/images/home/H01.png' alt='Img not found' className='w-full transition-all duration-500' />
                                    </div>
                                    <div className='bg-livos-green pl-[44px] pr-[44px] pt-[32px] pb-[32px]'>
                                        <div>
                                            <h3 className='text-[22px] mb-[20px] text-white font-teachers font-medium leading-[26px]'>Urban Farm <br /> Network</h3>
                                            <p className='text-livos-pink font-medium font-pretendard text-[13px]'>
                                                {t('main_eco').split('\n').map((line, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {index < array.length - 1 && <br />}
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* card */}
                            <section ref={thirdSectionRef} className={`text-livos-green p-4 transition-all duration-500 ${thirdSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                                <div>
                                    <img src='/assets/images/home/H02.png' alt='Img not found' className='w-full transition-all duration-500' />
                                    <div className='bg-livos-green pl-[44px] pr-[44px] pt-[32px] pb-[32px]'>
                                        <div>
                                            <h3 className='text-[22px] mb-[20px] text-white font-teachers font-medium leading-[26px]'>Farm to <br /> Table</h3>
                                            <p className='text-livos-pink font-medium font-pretendard text-[13px]'>
                                                {t('main_to_table').split('\n').map((line, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {index < array.length - 1 && <br />}
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* card */}
                            <section ref={fourthSectionRef} className={`text-livos-green p-4 mb-8 transition-all duration-500 ${fourthSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                                <div>
                                    <div>
                                        <img src='/assets/images/home/H03.png' alt='Img not found' className='w-full transition-all duration-500' />
                                    </div>
                                    <div className='bg-livos-green pl-[44px] pr-[44px] pt-[32px] pb-[32px]'>
                                        <div>
                                            <h3 className='text-[22px] mb-[20px] text-white font-teachers font-medium leading-[26px]' >New Paradigm <br></br> of Freshness</h3>
                                            <p className='text-livos-pink font-medium font-pretendard text-[13px]'>
                                                {t('main_order').split('\n').map((line, index, array) => (
                                                    <React.Fragment key={index}>
                                                        {line}
                                                        {index < array.length - 1 && <br />}
                                                    </React.Fragment>
                                                ))}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                ) : (
                    <div className=' mb-32'>
                        <div className='relative video-content'>
                            <video
                                autoPlay
                                loop
                                muted
                                playsInline
                                className="absolute z-10 min-w-full max-w-none"
                            >
                                <source
                                    src="https://livfarm-hompage-v2.s3.ap-northeast-2.amazonaws.com/home/video.mp4"
                                    type="video/mp4"
                                />
                                Your browser does not support the video tag.
                            </video>
                        </div>

                        {/* second text section */}
                        <section ref={firstSectionRef} className={`text-livos-green container transition-all duration-500 ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 50, marginBottom: 200 }}>
                            <div className='text-center gap gap-4'>
                                <h3 className='text-[40px] font-bold mb-[20px] font-pretendard'>{t('main_near')}</h3>
                                <p className='text-[24px] font-pretendard font-medium'>{t('main_freshness')}</p>
                            </div>
                        </section>

                        {/* image + text section */}
                        <section ref={secondSectionRef} className={`text-livos-green container mb-8 transition-all duration-500 ${secondSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div className='grid grid-cols-3 gap-4'>
                                <div className='flex items-center justify-center'>
                                    <div>
                                        <h3 className='mb-[30px] font-teachers font-medium lg:text-[50px] md:text-[40px] leading-[60px]'>Urban Farm <br /> Network</h3>
                                        <p className='md:text-[20px] lg:text-[24px] font-pretendard font-medium'>
                                            {t('main_eco').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-span-2'>
                                    <img src='/assets/images/home/H1.png' alt='Img not found' className='transition-all duration-500' />
                                </div>
                            </div>
                        </section>

                        {/* image + text section */}
                        <section ref={thirdSectionRef} className={`text-livos-green container mb-8 transition-all duration-500 ${thirdSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div className='grid grid-cols-3 gap-4'>
                                <div className='col-span-2'>
                                    <img src='/assets/images/home/H2.png' alt='Img not found' className='transition-all duration-500' />
                                </div>
                                <div className='flex items-center justify-center'>
                                    <div className='pl-4'>
                                        <h3 className='mb-[30px] font-teachers font-medium lg:text-[50px] md:text-[40px] leading-[60px]'>Farm to <br /> Table</h3>
                                        <p className='md:text-[20px] lg:text-[24px] font-pretendard font-medium'>
                                            {t('main_to_table').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* image + text section */}
                        <section ref={fourthSectionRef} className={`text-livos-green container mb-8 transition-all duration-500 ${fourthSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div className='grid grid-cols-3 gap-4'>
                                <div className='flex items-center justify-center'>
                                    <div>
                                        <h3 className='mb-[30px] font-teachers font-medium lg:text-[50px] md:text-[40px] leading-[60px]'>New <br></br>
                                            Paradigm <br></br>
                                            of Freshness</h3>
                                        <p className='md:text-[20px] lg:text-[24px] font-pretendard font-medium'>
                                            {t('main_order').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-span-2'>
                                    <img src='/assets/images/home/H3.png' alt='Img not found' className='transition-all duration-500' />
                                </div>
                            </div>
                        </section>
                    </div>
                )
            }
        </>
    );
}
