import './Mission.css';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import React from 'react';

export default function Mission() {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const { t } = useTranslation();
    const { ref: firstSectionRef, inView: firstSectionInView } = useInView({ triggerOnce: true });
    const { ref: secondSectionRef, inView: secondSectionInView } = useInView({ triggerOnce: true });

    return (
        <>
            {
                isSmallScreen ? <>
                    <div className='font-pretender'>
                        <div className="w-full bg-cover bg-center img-mission-content-sm">
                        </div>

                        {/* livfarm greens */}
                        <section ref={firstSectionRef} className={`text-livos-green container transition-all duration-500 ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 100, marginBottom: 100 }}>
                            <div className='text-center gap gap-4 '>
                                <div className='flex justify-center mb-[30px]'>
                                    <img src={`/assets/images/mission/mission_box.svg`} className='md:w-[188px] w-[100px]' />
                                </div>
                                <h3 className='text-[20px] font-bold mb-[15px]'>Feed the World with Less</h3>
                                <h3 className='text-[15px] font-medium mb-[40px]'>
                                    {t('mission_product').split('\n').map((line, index, array) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}</h3>
                                <p className='text-[12px] font-normal'>
                                    {t('m_mission_approach').split('\n').map((line, index, array) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}
                                </p>
                            </div>
                        </section>

                        {/* livfarm greens */}
                        <section ref={secondSectionRef} className={`text-livos-green container transition-all duration-500 pl-4 pr-4 ${secondSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginBottom: 100 }}>
                            <div className='flex justify-center mb-[30px]'>
                                <img src={`/assets/images/mission/core_value.svg`} className='md:w-[188px] w-[100px]' />
                            </div>
                            <div className='grid grid-cols-1 gap-8'>
                                <div className='flex justify-center mb-4'>
                                    <img src={`/assets/images/mission/${t('language')}/Mobile/corevalue1.svg`} alt='Img not found' />
                                </div>
                                <div className='flex justify-center mb-4'>
                                    <img src={`/assets/images/mission/${t('language')}/Mobile/corevalue2.svg`} alt='Img not found' />
                                </div>
                                <div className='flex justify-center mb-4'>
                                    <img src={`/assets/images/mission/${t('language')}/Mobile/corevalue3.svg`} alt='Img not found' />
                                </div>
                                <div className='flex justify-center mb-4'>
                                    <img src={`/assets/images/mission/${t('language')}/Mobile/corevalue4.svg`} alt='Img not found' />
                                </div>

                            </div>
                        </section>
                    </div>
                </> : <>
                    <div className='font-pretendard'>
                        <div className="w-full bg-cover bg-center img-mission-content">
                        </div>

                        {/* livfarm greens */}
                        <section ref={firstSectionRef} className={`text-livos-green container transition-all duration-500 ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 200, marginBottom: 200 }}>
                            <div className='text-center gap gap-4'>
                                <div className='flex justify-center mb-[60px]'>
                                    <img src={`/assets/images/mission/mission_box.svg`} className='md:w-[188px] w-[100px]' />
                                </div>

                                <h3 className='text-[40px] font-bold mb-[30px]'>Feed the World with Less</h3>
                                <h3 className='text-[24px] font-medium mb-[80px]'>
                                    {t('mission_product').split('\n').map((line, index, array) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}</h3>
                                <p className='text-[20px] font-normal'>
                                    {t('mission_approach').split('\n').map((line, index, array) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}
                                </p>
                            </div>
                        </section>

                        {/* livfarm greens */}
                        <section ref={secondSectionRef} className={`text-livos-green container transition-all duration-500 ${secondSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginBottom: 200 }}>
                            <div className='flex justify-center mb-[60px]'>
                                <img src={`/assets/images/mission/core_value.svg`} className='md:w-[188px] w-[100px]' />
                            </div>
                            <div className='grid grid-cols-2 gap-4 p-8'>
                                <div className='flex justify-end'>
                                    <img src={`/assets/images/mission/${t('language')}/corevalue_1.svg`} alt='Img not found' />
                                </div>
                                <div>
                                    <img src={`/assets/images/mission/${t('language')}/corevalue_2.svg`} alt='Img not found' />
                                </div>
                                <div className='flex justify-end'>
                                    <img src={`/assets/images/mission/${t('language')}/corevalue_3.svg`} alt='Img not found' />
                                </div>
                                <div>
                                    <img src={`/assets/images/mission/${t('language')}/corevalue_4.svg`} alt='Img not found' />
                                </div>

                            </div>
                        </section>
                    </div>
                </>
            }
        </>
    );
}
