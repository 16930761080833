// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import enTranslations from './assets/language/en.json';
import koTranslations from './assets/language/ko.json';

const resources = {
	en: {
	  translation: enTranslations
	},
	ko: {
	  translation: koTranslations
	}
  };

const language = localStorage.getItem('i18nextLng') || 'ko';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: language, 
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false 
    }
  });

export default i18n;
