import './App.css';
import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/home/Home';
import Greens from './components/greens/Greens';
import Menu from './components/menu/Menu';
import Location from './components/location/Location';
import Application from './components/application/Application';
import Mission from './components/mission/Mission';
import Partners from './components/partners/Partners';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './i18n';
import NotFound from './components/not-found/NotFound';

export default function App() {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Fetch categories
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}categories`);
        const data = JSON.parse(response.data);
        setCategories(data);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };

    fetchCategories();
  }, []);


  return (
    <>
      <BrowserRouter>
        <Header categories={categories} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/our-greens" element={<Greens />} />
          <Route path="/our-menus" element={<Menu categories={categories} />} />
          <Route path="/our-menus/:category" element={<Menu categories={categories} />} />
          <Route path="/locations" element={<Location />} />
          <Route path="/app" element={<Application />} />
          <Route path="/mission" element={<Mission />} />
          <Route path="/partners" element={<Partners />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}