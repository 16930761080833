import React from 'react';
import './Greens.css';
import { useMediaQuery } from 'react-responsive';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';

export default function Greens() {
    const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
    const { t } = useTranslation();
    const { ref: firstSectionRef, inView: firstSectionInView } = useInView({ triggerOnce: true });
    const { ref: secondSectionRef, inView: secondSectionInView } = useInView({ triggerOnce: true });
    const { ref: thirdSectionRef, inView: thirdSectionInView } = useInView({ triggerOnce: true });
    const { ref: fourthSectionRef, inView: fourthSectionInView } = useInView({ triggerOnce: true });

    return (
        <>
            {
                isSmallScreen ? <div>
                    <div className="w-full bg-cover bg-center img-content-sm">
                    </div>

                    {/* livfarm greens */}
                    <section ref={firstSectionRef} className={`text-livos-green container transition-all duration-500 ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 100, marginBottom: 100 }}>
                        <div className='text-center gap gap-4'>
                            <h3 className='text-[20px] font-bold mb-[30px]'>livfarm Greens</h3>
                            <p className='text-[15px] font-medium'>
                                {t('m_greens_livfarm').split('\n').map((line, index, array) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        {index < array.length - 1 && <br />}
                                    </React.Fragment>
                                ))}</p>
                        </div>
                        <div className='' style={{ marginTop: 60, marginBottom: 50 }}>
                            <img src='/assets/images/greens/greens_main_mobile.png' alt='Greens main' width={'100%'} />
                        </div>
                        <div className='text-center' style={{ marginBottom: 29 }}>
                            <p className='text-[16px] font-crimson font-semibold '>Pesticide-Free</p>
                            <p className='text-[12px] font-medium font-pretendard'>
                                {t('pesticide-free')}
                            </p>
                        </div>
                        <div className='text-center' style={{ marginBottom: 29 }}>
                            <p className='text-[16px] font-crimson font-semibold '>Just-Harvested</p>
                            <p className='text-[12px] font-medium font-pretendard'>
                                {t('just-harvested')}
                            </p>
                        </div>
                        <div className='text-center' style={{ marginBottom: 29 }}>
                            <p className='text-[16px] font-crimson font-semibold '>Hyper-Local</p>
                            <p className='text-[12px] font-medium font-pretendard'>
                                {t('hyper-local')}
                            </p>
                        </div>
                        <div className='text-center'>
                            <p className='text-[16px] font-crimson font-semibold '>Always-in Season</p>
                            <p className='text-[12px] font-medium font-pretendard'>
                                {t('always-in-season')}
                            </p>
                        </div>
                    </section>

                    {/* second text section */}
                    <section ref={secondSectionRef} className={`font-pretendard text-livos-green container transition-all duration-500 ${secondSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 100, marginBottom: 60 }}>
                        <div className='text-center gap gap-4'>
                            <h3 className='text-[20px] font-bold mb-[30px]'>Solution for the Freshest Meal</h3>
                            <p className='text-[15px] font-medium'>
                                {t('m_greens_solution').split('\n').map((line, index, array) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        {index < array.length - 1 && <br />}
                                    </React.Fragment>
                                ))}</p>
                        </div>
                    </section>

                    <div className='' style={{ marginBottom: 100 }}>
                        <section ref={thirdSectionRef} className={`font-pretendard text-livos-green p-8 transition-all duration-500 ${thirdSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div>
                                <div className='w-full'>
                                    <img src='/assets/images/greens/Mobile/content_1.png' alt='Content' className='w-full' />
                                </div>
                                <div className='p-8'>
                                    <div className='text-center'>
                                        <h3 className='text-[22px] font-bold mb-[20px]'>
                                            {t('greens_urban_network').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}</h3>
                                        <p className='text-[13px] font-medium'>
                                            {t('greens_innovation').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}</p>
                                    </div>
                                </div>
                            </div>
                        </section>

                        {/* card */}
                        <section ref={fourthSectionRef} className={`font-pretendard text-livos-green p-8 transition-all duration-500 ${fourthSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div>
                                <div className='w-full'>
                                    <img src='/assets/images/greens/Mobile/content_2.png' alt='Content' className='w-full' />
                                </div>
                                <div className='p-8'>
                                    <div className='text-center'>
                                        <h3 className='text-[22px] font-bold mb-[20px]'>
                                            {t('greens_future').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}</h3>
                                        <p className='text-[13px] font-medium'>
                                            {t('greens_network').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}</p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div> : <>
                    <div className='font-pretendard'>
                        <div className="w-full bg-cover bg-center img-content">
                        </div>

                        {/* livfarm greens */}
                        <section ref={firstSectionRef} className={`text-livos-green container transition-all duration-500 ${firstSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 200 }}>
                            <div className='text-center gap gap-4'>
                                <h3 className='text-[40px] font-bold font-pretendard mb-[30px]'>livfarm Greens</h3>
                                <p className='text-[24px] font-normal font-pretendard'>
                                    {t('greens_livfarm').split('\n').map((line, index, array) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}</p>
                            </div>
                            <div className='lg:pl-40 lg:pr-40 pl-10 pr-10' style={{ marginTop: 60, marginBottom: 200, }}>
                                {/* <img src='/assets/images/greens/G1.png' width={'100%'} /> */}
                                <img src={`/assets/images/greens/${t('language')}/greens.svg`} alt='Img' width={'100%'} />
                            </div>
                        </section>

                        {/* livfarm solution */}
                        <section ref={secondSectionRef} className={`text-livos-green container transition-all duration-500 ${secondSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 200, marginBottom: 200 }}>
                            <div className='text-center gap gap-4'>
                                <h3 className='text-[40px] font-bold mb-[30px]'>Solution for the Freshest Meal</h3>
                                <p className='text-[24px] font-medium'>
                                    {t('greens_solution').split('\n').map((line, index, array) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            {index < array.length - 1 && <br />}
                                        </React.Fragment>
                                    ))}</p>
                            </div>
                        </section>

                        {/* image + text section */}
                        <section ref={thirdSectionRef} className={`text-livos-green container transition-all duration-500 mb-8 ${thirdSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginTop: 200, marginBottom: 200 }}>
                            <div className='grid grid-cols-5 gap-4 font-medium'>
                                <div className='flex items-center text-center justify-center col-span-2'>
                                    <div className=''>
                                        <h3 className='xl:text-[36px] font-semibold lg:text-[25px] mb-[30px]'>
                                            {t('greens_urban_network').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </h3>
                                        <p className='xl:text-[24px] lg:text-[18px]'>
                                            {t('greens_innovation').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                                <div className='col-span-3'>
                                    <img src='/assets/images/greens/G2.png' alt='G2' />
                                </div>
                            </div>
                        </section>
                        {/* image + text section */}
                        <section ref={fourthSectionRef} className={`text-livos-green container transition-all duration-500 mb-8 ${fourthSectionInView ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`} style={{ marginBottom: 200 }}>
                            <div className='grid grid-cols-3 gap-4'>
                                <div className='col-span-2'>
                                    <img src='/assets/images/greens/G3.png' alt='G3' />
                                </div>
                                <div className='flex items-center text-center justify-center'>
                                    <div className=''>
                                        <h3 className='xl:text-[36px] font-semibold lg:text-[25px] mb-[30px] font-medium'>
                                            {t('greens_future').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </h3>
                                        <p className='xl:text-[24px] lg:text-[18px] font-medium'>
                                            {t('greens_network').split('\n').map((line, index, array) => (
                                                <React.Fragment key={index}>
                                                    {line}
                                                    {index < array.length - 1 && <br />}
                                                </React.Fragment>
                                            ))}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </>
            }
        </>
    );
}
