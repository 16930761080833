import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from '@headlessui/react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import './Header.css';

interface Category {
    _id: string;
    id: string;
    title: string;
}

interface CategoryProps {
    categories: Category[];
}

const Header: React.FC<CategoryProps> = ({ categories }) => {
    const { i18n } = useTranslation();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const location = useLocation();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    const changeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        localStorage.setItem('i18nextLng', language);
    };

    useEffect(() => {
        const excludedPathsRegex = /^\/(app|our-menus|locations)(\/.*)?$/;
        if (excludedPathsRegex.test(location.pathname)) {
            setIsScrolled(true);
            return;
        }
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [location.pathname]);

    return (
        <>
            <nav className={`fixed w-full top-0 z-20 transition-all duration-300 font-teachers font-light ${isScrolled ? 'bg-white shadow-lg text-livos-green' : 'scroll-header text-white'}`}>
                <div className="max-w-screen-2xl flex flex-wrap items-center justify-between mx-auto p-4 pt-6 pb-6 relative">
                    <button
                        aria-controls="default-sidebar"
                        type="button"
                        className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden absolute left-4"
                        onClick={toggleSidebar}
                    >
                        <span className="sr-only">Open sidebar</span>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <line x1="9" y1="9.5" x2="30" y2="9.5" stroke={isScrolled ? "#063F34" : '#fff'} />
                            <line x1="9" y1="19.5" x2="30" y2="19.5" stroke={isScrolled ? "#063F34" : '#fff'} />
                            <line x1="9" y1="29.5" x2="30" y2="29.5" stroke={isScrolled ? "#063F34" : '#fff'} />
                        </svg>
                    </button>
                    <div className="flex-1 flex justify-center md:justify-start">
                        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                            <img src={isScrolled ? '/assets/images/logos/livfarm_logo_green.png' : '/assets/images/logos/livfarm_logo_white.png'} className="h-6" alt="livos Logo" />
                        </a>
                    </div>
                    <div className="hidden w-full md:block md:w-auto">
                        <ul className="font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:border-0 items-center">
                            <li>
                                <a href="/our-greens" className="block py-2 px-3 text-sm rounded hover:text-livos-inactive md:hover:bg-transparent md:border-0 md:p-0" aria-current="page">OUR GREENS</a>
                            </li>
                            <li>
                                <a href="/our-menus/salad" className="block py-2 px-3 rounded text-sm hover:text-livos-inactive md:hover:bg-transparent md:border-0 md:p-0">OUR MENUS</a>
                            </li>
                            <li>
                                <a href="/locations" className="block py-2 px-3 rounded text-sm hover:text-livos-inactive md:hover:bg-transparent md:border-0 md:p-0">LOCATIONS</a>
                            </li>
                            <li>
                                <a href="/app" className="block py-2 px-3 rounded text-sm hover:text-livos-inactive md:hover:bg-transparent md:border-0 md:p-0">APP</a>
                            </li>
                            <li>
                                <a href="/mission" className="block py-2 px-3 rounded text-sm hover:text-livos-inactive md:hover:bg-transparent md:border-0 md:p-0">MISSION</a>
                            </li>
                            <li>
                                <a href="/partners" className="block rounded text-sm hover:text-livos-inactive md:hover:bg-transparent md:border-0 md:p-0">PARTNERS</a>
                            </li>
                            <li>
                                <div className="flex items-center">
                                    <li className="p-navbar__item">
                                        <a style={{ cursor: 'pointer' }} onClick={() => changeLanguage('ko')} className={`mr-[20px] ${isScrolled ? `${i18n.language === 'ko' ? 'text-livos' : 'text-livos-inactive'}` : `${i18n.language === 'ko' ? 'text-white underline' : 'text-white'}`}`}>KOR</a>
                                    </li><span>|</span>
                                    <li className="p-navbar__item">
                                        <a style={{ cursor: 'pointer' }} className={`ml-[20px] ${isScrolled ? `${i18n.language === 'en' ? 'text-livos' : 'text-livos-inactive'}` : `${i18n.language === 'en' ? 'text-white underline' : 'text-white'}`}`} onClick={() => changeLanguage('en')}>ENG</a>
                                    </li>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
                {!isScrolled && <hr className="border-1" />}
            </nav>
            <Transition show={isSidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={closeSidebar}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-50" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-300"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-300"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative flex w-64 h-full bg-white dark:bg-gray-900 shadow-xl">
                                <div className="flex-1 h-full overflow-y-auto text-livos-green">
                                    <div className="flex items-center justify-between p-4">
                                        <div className="ms-2 font-pretendard font-normal">MENU</div>
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 dark:text-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                            onClick={closeSidebar}
                                        >
                                            <span className="sr-only">Close sidebar</span>
                                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="px-4 font-teachers">
                                        <ul className="space-y-2 font-pretendard font-normal">
                                            <li>
                                                <a href="/our-greens" className="flex items-center p-2 rounded-lg hover:bg-gray-100">
                                                    <span className="">Our Greens</span>
                                                </a>
                                            </li>
                                            <li>
                                                <button
                                                    className="flex items-center p-2 rounded-lg hover:bg-gray-100 w-full text-left"
                                                    onClick={toggleDropdown}
                                                >
                                                    <span className="flex-1 whitespace-nowrap">Our Menu</span>
                                                    <svg className={`w-5 h-5 transform transition-transform ${isDropdownOpen ? 'rotate-0' : '-rotate-90'}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                    </svg>
                                                </button>
                                                <Transition
                                                    show={isDropdownOpen}
                                                    enter="transition ease-out duration-200"
                                                    enterFrom="opacity-0 transform -translate-y-2"
                                                    enterTo="opacity-100 transform translate-y-0"
                                                    leave="transition ease-in duration-150"
                                                    leaveFrom="opacity-100 transform translate-y-0"
                                                    leaveTo="opacity-0 transform -translate-y-2"
                                                >
                                                    <ul className="pl-6 mt-2 space-y-2 text-sm">
                                                        {categories.length > 0 && (
                                                            categories.map((item) => (
                                                                <li key={item._id}>
                                                                    <a href={`/our-menus/${item.id}`} className="block p-2 rounded-lg hover:bg-gray-100">{item.title}</a>
                                                                </li>
                                                            ))
                                                        )}
                                                    </ul>
                                                </Transition>
                                            </li>
                                            <li>
                                                <a href="/locations" className="flex items-center p-2 rounded-lg hover:bg-gray-100">
                                                    <span className="">Locations</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/app" className="flex items-center p-2 rounded-lg hover:bg-gray-100">
                                                    <span className="">App</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/mission" className="flex items-center p-2 rounded-lg hover:bg-gray-100">
                                                    <span className="">Mission</span>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/partners" className="flex items-center p-2 rounded-lg hover:bg-gray-100">
                                                    <span className="">Partners</span>
                                                </a>
                                            </li>
                                            <li>
                                                <div className="flex items-center pl-2">
                                                    <li className="p-navbar__item">
                                                        <a style={{ cursor: 'pointer' }} onClick={() => changeLanguage('ko')} className={`mr-[20px] ${i18n.language === 'ko' ? 'text-livos' : 'text-livos-inactive'}`}>KOR</a>
                                                    </li><span>|</span>
                                                    <li className="p-navbar__item">
                                                        <a style={{ cursor: 'pointer' }} className={`ml-[20px] ${i18n.language === 'en' ? 'text-livos' : 'text-livos-inactive'}`} onClick={() => changeLanguage('en')}>ENG</a>
                                                    </li>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition >
        </>
    );
}

export default Header;
