import React from 'react';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-t from-livos-green to-livos-light text-white">
      <div className="text-center">
        <h1 className="text-9xl font-bold animate-bounce">404</h1>
        <p className="text-2xl mt-4">페이지를 찾을 수 없습니다</p>
        <p className="mt-4">
          페이지가 존재하지 않거나, 사용할 수 없는 페이지입니다.<br></br>
          입력하신 주소가 정확한지 다시 한 번 확인해주세요.
        </p>
      </div>
      <div className="absolute bottom-14 w-full flex justify-center mt-32">
        <div className="flex space-x-4">
          <div className="w-4 h-4 bg-white rounded-full animate-bounce"></div>
          <div className="w-4 h-4 bg-white rounded-full animate-bounce animation-delay-200"></div>
          <div className="w-4 h-4 bg-white rounded-full animate-bounce animation-delay-400"></div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
