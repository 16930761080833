
export default function Footer() {
    return (
        <>
            <footer className="bg-livos-green">
                <div className="mx-auto w-full">
                    <div className="grid grid-cols-2 gap-8 px-4 py-6 lg:py-8 md:grid-cols-4 text-white sm:p-6">
                        <div className="">
                            <a href="/" className="flex md:justify-center">
                                <img src="/assets/images/logos/livfarm_logo_white.png" className="h-6 me-3" alt="FlowBite Logo" />
                            </a>

                        </div>
                        <div className="col-span-2">
                            <div className="grid grid-cols-3 md:grid-cols-2 lg:grid-cols-3">
                                <div className="col-span-2 md:col-span-1">
                                    <ul className="text-[12px] leading-[24px] font-teachers">
                                        <li className="mb-4">
                                            <p>상호명  (주)퓨처커넥트</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>대표자 강길모</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>전화번호 02-6081-8179</p>
                                        </li>
                                        <li className="mb-4 min-w-[213px]">
                                            <p>소재지  서울특별시 서초구 강남대로 311, 6층 </p>
                                        </li>
                                        <li className="mb-4">
                                            <p>사업자등록번호  801-81-01885</p>
                                        </li>
                                        <li className="mb-4">
                                            <p>문의 <a href="mailto:admin@futureconnect.co.kr">admin@futureconnect.co.kr</a></p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="grid grid-cols-3 md:grid-cols-1 font-pretendard">
                                    <ul className="col-start-2 md:col-start-1 text-xs md:text-left text-right leading-[24px]">
                                        <li className="mb-4">
                                            <a href="https://www.instagram.com/livfarm_official" className="hover:underline ">Instagram</a>
                                        </li>
                                        <li className="mb-4">
                                            <a href="https://www.youtube.com/@livfarmfarm" className="hover:underline ">Youtube</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                        <div className="hidden md:block">
                            <h2 className="mb-6 text-md font-teachers">The Farm Beside You</h2>

                        </div>
                    </div>
                    <hr className="border-1" />
                    <div className="px-4 py-6 flex items-center justify-center text-center">
                        <span className="text-[12px] text-gray-400 font-pretendard">
                            © Copyright 2024 Futureconnect. All rights reserved.
                        </span>
                    </div>
                </div>
            </footer>
        </>
    );
}